header h4 {
    margin: unset!important;
    padding: 1rem 0;
}
header div {
    padding: 2rem 0;
}
.has-cursor {
    cursor: pointer;
}
.side-title {
    font-size: 2rem;
    padding: 2rem 0;
}
.content-title {
    font-weight: 500;
    font-size: 1.2rem;
    margin-top: 0!important;
}
.card-panel {
    border-radius: 10px!important;
}
section {
    padding: 2rem 0 4rem 0;
}
.section-title {
    font-weight: 500;
    padding: 2rem 0.5 1rem 0.5;
}
.info {
    font-size: 1.2rem;
    padding: 0 .5rem 1rem .5rem;
}
#floater {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
}
